.filter-box {
  li {
    color: #1cb0ff;
    margin-left: 1rem;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  // .active-li {
  //   font-weight: bold;
  //   margin-left: 0;
  //   &::before {
  //     content: '\A';
  //     width: 10px;
  //     height: 10px;
  //     margin-right: 0.38rem;
  //     border-radius: 50%;
  //     background: #ff6600;
  //     display: inline-block;
  //   }

  //   &::after {
  //     content: '+';
  //     color: #ff6600;
  //     margin-left: 0.38rem;
  //     display: inline-block;
  //   }
  // }
}

.filter-card {
  background-color: #f4fbff !important;
  /* Shadow1 */

  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08) !important;
  border-radius: 8px !important;
  .card-header {
    background-color: #f4fbff !important;
    border: 0;
    .card-title {
      color: #000;
    }
  }
}

.listing-search-box {
  position: relative;
  z-index: 1;
  background: linear-gradient(90deg, #043572 0%, #0961cd 101.04%);
  backdrop-filter: blur(10px);
  .container:first-child {
    min-height: 0;
  }

  .advanced-btn {
    background: transparent;
    box-shadow: none !important;
    &:hover {
      background: transparent;
    }
    &:focus {
      background: transparent;
    }
  }

  .search-btn {
    background: linear-gradient(180deg, #6971ff 0%, #1cb0ff 100%);
    /* Shadow1 */

    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    width: 100%;
  }
}

.carousel-indicators {
  bottom: unset !important;
  margin-top: 3rem !important;
  li {
    height: 10px !important;
    width: 10px !important;
    border-radius: 50px;
    border: none !important;
    opacity: 1 !important;
  }
  li.active {
    background: linear-gradient(180deg, #6971ff 0%, #1cb0ff 100%);
  }
}
