$custom-bright-gray: #323746;
$custom-dark-gray: #4f4f4f;
$custom-solid-blue: #0c5481;
$custom-white-gray: #e5e6e7;
$custom-dark-white: #a3a7b1;
$custom-brighter-dark-white: #8e95ac;
$custom-green: #4fba6f;
$custom-gray-blue: #005baa;
$custom-light-gray: #d4e0e7;
$custom-dark-blue: #39f;
$custom-bright-blue: #4190c7;
$custom-light-blue: #1cb0ff;
$custom-info: #48a0dc;
$custom-dark-yellow: #b27b34;
$custom-gray: #e0e0e0;
$custom-black-blue: #042752;
$custom-blue: #1cb0ff;

@font-face {
  font-family: SFProdisplay;
  src: url('./font/SFProDisplay-Regular.ttf');
}

body {
  // Review font-family class
  font-family: SFProdisplay !important;
}

.letter-spacing-lg {
  letter-spacing: 0.24em;
}

.wrapper {
  width: 100%;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.color-red {
  color: #cf3030;
}

.color-muted {
  color: #ffffffb5 !important;
}

.header-listing-text {
  color: rgba(4, 43, 90, 0.8) !important;
}

.color-dark-yellow {
  color: $custom-dark-yellow;
}

.btn-listing-register {
  font-size: 0.89rem;
  line-height: 1.4rem;
  padding: 0.5rem 2.5rem;
  text-transform: uppercase;
  border-radius: 6px;
}
.rc-slider-track,
.text-anft-gradiant {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
  background: linear-gradient(90deg, #6971ff 0%, #1cb0ff 100%);
  --cui-btn-hover-bg: linear-gradient(90deg, #6971ff 0%, #1cb0ff 100%);
  --cui-btn-active-bg: linear-gradient(90deg, #6971ff 0%, #1cb0ff 100%);
}

.text-gradient {
  /* ff 3.6+ */
  background: -moz-linear-gradient(180deg, rgba(105, 113, 255, 1) 0%, rgba(28, 176, 255, 1) 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(180deg, rgba(105, 113, 255, 1) 0%, rgba(28, 176, 255, 1) 100%);

  /* opera 11.10+ */
  background: -o-linear-gradient(180deg, rgba(105, 113, 255, 1) 0%, rgba(28, 176, 255, 1) 100%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1CB0FF', endColorstr='#6971FF', GradientType=1 );

  /* ie 10+ */
  background: -ms-linear-gradient(180deg, rgba(105, 113, 255, 1) 0%, rgba(28, 176, 255, 1) 100%);

  /* global 94%+ browsers support */
  background: linear-gradient(180deg, rgba(105, 113, 255, 1) 0%, rgba(28, 176, 255, 1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-outline-gradient {
  display: inline-block;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(180deg, rgb(105, 113, 255) 0%, rgba(28, 176, 255, 1) 100%) border-box;
  border: 1px solid transparent;
  color: #6971ff;
}

.btn-outline-gradient:hover,
.btn-outline-gradient:focus {
  background: linear-gradient(180deg, rgba(105, 113, 255, 1) 0%, rgba(28, 176, 255, 1) 100%) border-box;
  border: 1px solid transparent;
  color: #fff;
}

.card-img-overlay {
  // height: max-content;

  // /* ff 3.6+ */
  // background: -moz-linear-gradient(0deg, rgba(28, 176, 255, 0.6) 0%, rgba(4, 43, 90, 0.6) 100%);

  // /* safari 5.1+,chrome 10+ */
  // background: -webkit-linear-gradient(0deg, rgba(28, 176, 255, 0.6) 0%, rgba(4, 43, 90, 0.6) 100%);

  // /* opera 11.10+ */
  // background: -o-linear-gradient(0deg, rgba(28, 176, 255, 0.6) 0%, rgba(4, 43, 90, 0.6) 100%);

  // /* ie 6-9 */
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1CB0FF', endColorstr='#042B5A', GradientType=1 );

  // /* ie 10+ */
  // background: -ms-linear-gradient(0deg, rgba(28, 176, 255, 0.6) 0%, rgba(4, 43, 90, 0.6) 100%);

  // /* global 94%+ browsers support */
  // background: linear-gradient(0deg, rgba(28, 176, 255, 0.6) 0%, rgba(4, 43, 90, 0.6) 100%);

  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgb(4, 43, 90, 0)),
    color-stop(0, rgb(4, 43, 90, 0)),
    color-stop(70%, rgb(4, 43, 90, 0)),
    to(rgba(0, 0, 0, 1))
  );
  background-image: -o-linear-gradient(
    top,
    rgb(4, 43, 90, 0) 0,
    rgb(4, 43, 90, 0) 0,
    rgb(4, 43, 90, 0) 70%,
    rgb(4, 43, 90, 1) 100%
  );
  background-image: linear-gradient(
    to bottom,
    rgb(4, 43, 90, 0) 0,
    rgb(4, 43, 90, 0) 0,
    rgb(4, 43, 90, 0) 70%,
    rgb(4, 43, 90, 1) 100%
  );
}

// .card-img-overlay:hover {
//   background: none;
// }

.text-black-blue {
  color: #042b5a;
}

.color-bluegran {
  color: $custom-blue;
}

.listing-pill {
  padding: 0.575em 1.575em;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 0.8em;
}

.btn-back {
  color: $custom-dark-blue;
  border: 1px solid $custom-dark-blue;
  background-color: #fff !important;
}

.width-max-content {
  max-width: max-content;
}

.text-decoration-none {
  text-decoration: none;
}

.FO-Footer {
  // padding: 1.5rem 0;
  // background-color: #00335a;

  .footer-lh {
    line-height: 2.1rem;
  }

  .footer-start {
    background-color: $custom-gray-blue;

    .FO-white {
      color: #fff;
    }

    h5,
    h6 {
      color: #fff;
      margin-bottom: 0;
    }

    .address-text {
      color: $custom-brighter-dark-white;
    }

    .content-text {
      color: #fff;
      font-size: 0.8rem;
    }

    .content-text-bold {
      font-weight: 600;
      font-size: 0.8rem;
    }

    .send-btn {
      background-color: $custom-green;
      padding: 0.2rem 1.5rem;
      text-transform: capitalize;
    }

    hr {
      background-color: $custom-dark-white;
    }

    .read-more-text {
      font-size: 0.8rem;
      color: $custom-gray-blue;
    }
    // Replace importants
    // 1 liner
    .info-box {
      padding-right: 4rem;
    }
    .contact-box {
      padding: 0 3rem;
    }
    .menu-box {
      padding-left: 6rem;
    }

    @media (max-width: 1199px) {
      .contact-box {
        padding-left: 0;
      }
      .menu-box {
        padding-left: 0;
      }
      .info-box {
        padding-left: 0;
      }
    }

    .footer-background-wraper {
      position: absolute;
      top: 0;
      width: 100%;
      left: 0;
      .footer-background {
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  .footer-end {
    background-color: $custom-solid-blue;

    .end-text {
      color: #ffffff !important;
      font-size: 0.9rem;
    }
    .social-links {
      a {
        font-size: 0.5rem;
        display: inline-block;
        line-height: 1;
        padding: 3px 0;
        margin-right: 4px;
        text-align: center;
        width: 1rem;
        height: 1.7rem;
        transition: 0.3s;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.background-color-transparent {
  z-index: 1;
  background-color: transparent;
}

.background-color-header {
  z-index: 1;
  background-color: transparent;
}

.color-transparent {
  color: transparent;
}

.max-header-width {
  max-width: 1200px;
}

.header-text {
  font-size: 0.89rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.nav-item-header-active {
  border-bottom: 2px solid #ffffff;
}

.nav-item-header-active a {
  border: 0;
}

@media (max-width: 1200px) {
  .max-header-width {
    max-width: 1170px;
  }
}

.gray-background-color {
  background-color: $custom-light-gray;
}

.cover-container {
  .dashboard-title-container {
    z-index: 0;
    .title-wrapper {
      z-index: 0;
    }
  }

  @media only screen and (max-width: 992px) {
    .title-wrapper {
      .card {
        padding: 0.5rem !important;
      }
    }
  }

  .search-wraper {
    width: 100%;
    position: absolute;
    bottom: 1rem;
    left: 0;
    .search-content {
      background-color: #fff;
      // No pixel
      padding: 30px;
      border-radius: 4px;
      box-shadow: 0px 20px 40px 0px rgb(0 0 0 / 10%);
    }
  }
}

.list-style-none {
  list-style: none;
}

.cover-dashboard-search {
  height: 605px;
}

.cover-listing-search {
  height: 365px;
}

.cover-dashboard-image {
  height: 800px;
}

.cover-listing-image {
  height: 560px;
}

.cover-background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  .image-container {
    background-color: rgba(9, 72, 106, 0.4);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
}

.search-wrapper {
  z-index: 1;
  margin-top: -15rem;
  margin-bottom: 5rem;
}

.form-check-input:checked {
  background-color: $custom-dark-blue;
  border-color: $custom-dark-blue;
}

.cursor-pointer {
  cursor: pointer;
}

.content-wrapper {
  min-height: 50em;
}

.btn-info,
.bg-info {
  background-color: $custom-info !important;
}

.search-box {
  background: rgba(28, 176, 255, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  .search-label {
    text-transform: uppercase;
    color: #ffffff;
  }
  .search-select {
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 6px;
    background-color: var(--cui-form-select-bg, rgba(255, 255, 255, 0.2));

    // use a static image instead
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"%3E%3Cpath fill="white" d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-size: 16px 12px;

    option {
      background-color: rgba(28, 176, 255, 0.25);
      color: black;
    }
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fff;
    }
  }
  .search-select:disabled {
    opacity: 0.6;
  }

  .search-input {
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 6px;
    background-color: var(--cui-form-select-bg, rgba(255, 255, 255, 0.2));

    option {
      background-color: rgba(28, 176, 255, 0.25);
      color: black;
    }
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fff;
    }
  }
}

.bg-secondary {
  background-color: $custom-brighter-dark-white !important;
}

.db-mobile-dropdown {
  transform: translate3d(0px, 85px, 0px) !important;
  background: rgba(28, 176, 255, 0.25);
  backdrop-filter: blur(10px);
  width: 100%;
}

.mobile-dropdown {
  transform: translate3d(0px, 85px, 0px) !important;
  background: rgba(156, 220, 255, 0.65);
  backdrop-filter: blur(10px);
  width: 100%;
}

.custom-header-nav {
  z-index: 2;
  .dropdown-item:focus {
    background-color: $custom-info;
    color: #fff;
  }
}

.color-info {
  color: $custom-info !important;
}

.header-navigation {
  position: absolute;
  width: 100%;
  top: 5.6em;
  left: 0;
  .navigation-wrapper {
    line-height: 3em;
    background-color: $custom-bright-gray;
  }
}

.btn-search:hover {
  background-color: #04326b;
}

.register-listing-btn {
  background-color: $custom-green;
  color: #fff;
  padding-left: 10px;
}

.plus-icon-wrapper {
  border-radius: 5px;
  position: relative;
  background-color: $custom-green;
  border: 3px solid $custom-bright-gray;
  width: 25px;
  height: 25px;
  top: 20px;
  left: 13px;
  .plus-square-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    color: white;
  }
}

.carousel-button-group {
  // bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  right: 0;
}
.card-header-img,
.card-header-content {
  height: 70px;
}

.carosel-img-header {
  height: 3.2rem;
}

.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-md {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-xxl {
  font-size: 1.5rem;
}

.btn-arrow {
  background-color: $custom-bright-blue;
  // --cui-btn-hover-bg: #4190c7;
  // --cui-btn-active-bg: #4190c7;
  --cui-btn-hover-bg: $custom-bright-blue;
  --cui-btn-active-bg: $custom-bright-blue;
  &:hover {
    background-color: $custom-info !important;
  }
  &:focus {
    background-color: $custom-bright-blue;
  }
}

.text-ligh-blue {
  color: $custom-light-blue;
}

.btn-arrow-carousel {
  border: 2px solid $custom-light-blue;
  padding: 2px 6px;
  border-radius: 100%;
  --cui-btn-hover-bg: white;
  --cui-btn-active-bg: white;
  &:hover {
    border: 2px solid $custom-light-blue;
  }
  &:focus {
    border: 2px solid $custom-light-blue;
  }
}

.btn-widget {
  background-color: $custom-light-gray;
  --cui-btn-hover-bg: #d4e0e7;
  --cui-btn-active-bg: #d4e0e7;
}

.fw-500 {
  font-weight: 500;
}

.text-email {
  color: #004669;
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.modal-backdrop.show {
  opacity: 0.2;
}

.border-radius-50 {
  border-radius: 50%;
}

.required-field {
  &::after {
    content: '*';
    color: red;
    margin-left: 5px;
  }
}

//style ImageBox
.image-item {
  position: relative;
  height: 200px;
  line-height: 200px;
  .image-fill {
    max-width: -webkit-fill-available;
    max-height: 200px;
  }
}

.carousel-body {
  background-color: #f8f8f8;
  .infor-text {
    font-size: 0.9rem;
  }
  .label-color {
    color: #a0a0a0;
  }
}

.text-dark-blue {
  color: $custom-dark-gray !important;
}

.btn-mail-register {
  font-size: 0.7rem;
  height: 100%;
  width: 100%;
  border: 0;
}

.invalid-errors {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e55353;
}

.avatar-xxl {
  width: 6.25rem;
  height: 6.25rem;
  font-size: 2.1rem;
}

.text-area {
  height: 150px;
}

.aspect-ratio-box {
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
  background: white;
  position: relative;
  .aspect-ratio-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// .nav-underline .nav-link.active,
// .nav-underline .nav-link.active span {
//   color: $custom-info !important;
//   border-color: $custom-info;
// }

// .nav-underline .nav-link:hover {
//   border-color: $custom-info;
// }

.popverLoading {
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 15px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 0;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $primary;
    opacity: 0.8;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  .modal-content {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.form-floating {
  .form-select {
    padding-top: 1.625rem !important;
    padding-bottom: 0.625rem !important;
    font-size: 0.85rem;
    height: calc(3.25rem + 2px);
  }
  .form-label {
    font-size: 0.8rem;
  }
  .form-control {
    font-size: 0.85rem;
    height: calc(3.25rem + 2px);
  }
}

.list-revenue {
  padding: 0 0.75rem !important;
  .list-group-item:first-child {
    color: #3ba2eb;
  }
  .list-group-item:nth-child(2) {
    color: #f36384;
  }
  .list-group-item:nth-child(3) {
    color: #6566f1;
  }
  .list-group-item:nth-child(4) {
    color: #4bc0c0;
  }
  .list-group-item:nth-child(5) {
    color: #7d82e4;
  }
  .list-group-item:nth-child(6) {
    color: #a6b7be;
  }
  .list-group-item:nth-child(7) {
    color: #161697;
  }
  .list-group-item:nth-child(8) {
    color: #2eb85c;
  }
  .list-group-item:last-child {
    color: #4f5d73;
  }
}

.trade-office-logo {
  width: 200px;
  height: 200px;
}

.bg-login {
  background-image: url('../img/side_img.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.form-multi-select {
  .dropdown-menu {
    width: 100%;
    margin-left: -12px;
    margin-top: 6px;
  }
}

.btn:disabled {
  border: none;
}

.w-90 {
  width: 90% !important;
}